import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { TYPE_METHOD, PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS, SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { IContact } from '~/types/seo'
import { PATH_IMAGES } from '~/constants/path'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export const getFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    NAVIGATIONS: [
      {
        title: 'Thể thao',
        subCategories: [
          { title: 'Lịch thi đấu', link: SPORTS_URLS.SCHEDULES },
          { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
        ]
      },
      {
        title: 'Trò chơi',
        subCategories: [
          { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
          { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
          { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
          { title: 'Games bài', link: LOBBY_GAME_URLS.GAME_BAI },
          { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
          { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
          { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
          { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
        ]
      },
      {
        title: 'Về chúng tôi',
        subCategories: [
          { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
          { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}` },
          { title: 'Bảo mật', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}` },
          { title: 'Chơi games có trách nhiệm', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RESPONSIBILITY}` },
          { title: 'Quy định chung', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RULE}` }
        ]
      },
      {
        title: 'Hỗ trợ 24/7',
        subCategories: [
          { title: 'Trực tuyến 24/7', type: 'BLANK', link: `${result?.livechat?.url}` },
          { title: 'Telegram CSKH', type: 'BLANK', link: `${result?.telegram?.url}` },
          { title: 'Hướng dẫn Nạp/Rút', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.DEPOSIT}` }
        ]
      },
      {
        title: 'Thông tin',
        subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
      }
    ]
  }
}

export const DEPOSIT_LIST = [
  {
    title: 'CodePay',
    image: `${PATH_IMAGE}codepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA
  },
  {
    title: 'P2P',
    image: `${PATH_IMAGE}p2p.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA
  },
  {
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO
  },
  {
    title: 'Ví điện tử',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO
  },
  {
    title: 'Viettel Money',
    image: `${PATH_IMAGE}viettel-money.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO
  },
  {
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD
  }
]

export const getFooterContent = () => {
  const { $config } = useNuxtApp()

  return {
    SLOGAN_DESCRIPTION: `${$config.public.BRANCH_NAME} - Nhà cái đẳng cấp Châu Âu. ${$config.public.BRANCH_NAME} đem đến cho khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao Ảo, Casino, Number Games, Keno, Sòng Bài Số...`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.public.BRANCH_NAME} All Rights Reserved.`
  }
}
export const getFooterContact = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    SUPPORT_MENU: [
      {
        name: 'Live chat 24/7',
        url: `${result?.livechat?.url}`,
        activeUrl: [],
        isLiveChat: true,
        icon: PATH_IMAGES + 'icon-livechat.webp',
        iconMB: PATH_IMAGES + 'icon-livechat-mb.svg'
      },
      {
        name: `Telegram ${$config.public.BRANCH_NAME}`,
        url: `${result?.telegram?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-telegram.webp',
        iconMB: PATH_IMAGES + 'icon-telegram-mb.svg'
      },
      {
        name: 'Cộng đồng',
        url: `${result?.cskh?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-community.webp',
        iconMB: PATH_IMAGES + 'icon-community-mb.svg'
      },
      {
        name: 'Hotline',
        url: `tel:${result?.hotline?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-phone.webp',
        iconMB: PATH_IMAGES + 'icon-phone-mb.svg'
      },
      {
        name: 'Fanpage',
        url: `${result?.fanpage?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES + 'icon-fb.webp',
        iconMB: PATH_IMAGES + 'icon-fb-mb.svg',
        className: 'icon-fanpage'
      }
    ]
  }
}
export const getFooterAboutUs = () => {
  const { $config } = useNuxtApp()

  return {
    INTRODUCE_LIST: [
      {
        name: 'Giới thiệu',
        icon: PATH_IMAGES + 'icon-introduce.svg',
        url: PAGE_URLS.INTRODUCTION
      },
      {
        name: 'Quy định',
        icon: PATH_IMAGES + 'icon-policy.svg',
        url: PAGE_URLS.RULE
      },
      {
        name: 'Bảo mật',
        icon: PATH_IMAGES + 'icon-privacy.svg',
        url: PAGE_URLS.PRIVACY
      },
      {
        name: 'Hướng dẫn',
        icon: PATH_IMAGES + 'icon-desc.svg',
        url: PAGE_URLS.GUIDE
      },
      {
        name: 'Tin tức',
        icon: PATH_IMAGES + 'icon_news.svg',
        url: PAGE_URLS.NEWS,
        type: 'news'
      }
    ]
  }
}
